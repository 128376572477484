import * as React from "react"

import { Container } from "../../components"
import { Category } from "../../section"
import Seo from "../../components/seo"

const Haircare = () => (
  <Container>
    <Seo
      title="ヘアケア"
      description="スキンケアやボディメイクは気を遣っているのに髪の毛のことは疎かになってませんか？知らないうちに頭皮や髪の毛が傷み、抜け毛や白髪で悩んでいる人を今までよく見てきました。毎日のシャンプーも立派な美容です。ここではメンズのヘアケアに関する知識やおすすめ商品を紹介しています。"
    />
    <Category Title="Haircare" SubTitle="ヘアケア" theCategory="haircare" />
  </Container>
)

export default Haircare
